<template
        >
  <div
    v-loading="!show"
    v-touch:swiperight="rightSlide"
    v-if="modalType == 0"
    :class="backdrop == 1 ? 'backImg JH-body' : 'JH-body'"
  >
    <!--    <div style="padding: 6px 0 2px 6px"><button class="detailedBtn"  @click="backRouter"><i class="el-icon-back"></i></button></div>-->

    <div class="header_JH">
      <span>请选择：</span>
    </div>
    <div class="content_JH" v-infinite-scroll="scrollHandler">
      <p
        v-for="(item, index) in dataList"
        :key="item.id"
        v-if="item.enableReport == 0"
      >
        <span class="backgroundColor" v-if="item.enableReport == 0"></span>
        <span
          class="color pointer"
          @click="showReportList(item)"
          v-if="item.enableReport == 0"
          style="color: #2b314b !important"
          >{{ item.reportListName }}</span
        >
        <span
          class="backgroundColor pointer"
          @click="showTimeSelect('timeSelect' + index, item)"
          :id="'timeSelect' + index"
          v-if="item.enableReport == 0"
          >查看往期
          <span class="borderColor pointer"></span>
        </span>
      </p>
    </div>
    <GoDatePicker
      :index="timeType"
      :effectiveTime="effectiveTime"
      @input="timeChanged"
      id="dataPanel"
      v-model="timeValue"
      :isShow.sync="showTime"
      :top="top"
      :bottom="bottom"
    ></GoDatePicker>
    <div class="paginationClass">
      <el-pagination
        @size-change="paginationChange('pageSize', $event)"
        @current-change="paginationChange('page', $event)"
        :current-page.sync="page"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
  <div id="reportListIndex" :class="backdrop == 1 ? 'backImg' : ''" v-else>
    <div style="text-align: center" v-if="dataList.length == 0">
      <span>暂无数据</span>
    </div>
    <div class="listArea" v-for="(item,index) in dataList"
         :key="item.id"
         v-if="item.enableReport == 0">
      <div class="listContent">
        <span @click="showReportList(item)">{{ item.reportListName }}</span>
      </div>
      <div class="toWatch" style="height: 22px">
        <p
                @click="showTimeSelect('timeSelect' + index, item)"
                :id="'timeSelect' + index"
                v-if="item.enableReport == 0">
<!--                v-if="item.enableReport == 0 && item.clickState == 0">-->
          查看往期<span>>></span>
        </p>
      </div>
    </div>
    <GoDatePicker
            :index="timeType"
            :effectiveTime="effectiveTime"
            @input="timeChanged"
            id="dataPanel"
            v-model="timeValue"
            :isShow.sync="showTime"
            :top="top"
            :bottom="bottom"
    ></GoDatePicker>
    <div class="paginationClass">
      <el-pagination
              @size-change="paginationChange('pageSize', $event)"
              @current-change="paginationChange('page', $event)"
              :current-page.sync="page"
              :page-sizes="[15, 30, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import GoDatePicker from "@/components/dataPanel/date-picker.vue";
import { formatDate } from "@/utils/date.js";
import {
  sendBuriedPointTotal,
  getReportTimerReport,
  getEffectiveTime,
  getSignature,
  findWXModularStyleByOrgId
} from "@/api/api";
export default {
  name: "reportLists",
  components: {
    GoDatePicker,
  },
  data() {
    return {
      modalType:0,
      backdrop:0,
      orgId: "",
      departmentName: "",
      newDay: [],
      effectiveTime: [],
      timeType: 0,
      backId: "",
      loading: true,
      appid: "",
      moduleId: "",
      reportName: "",
      show: false,
      reportTimerId: "", //报告id
      moduleName: this.$route.query.moduleName,
      name: "",
      code: "",
      agentid: "",
      id: "",
      url: "",
      visitTime: "",
      startTime: "",
      timeValue: undefined,
      dataList: [],
      showTime: false,
      selectPanelNode: null, //选择的节点
      selectItem: null, //选择的行数据
      top: null,
      bottom: 0,
      total: 100,
      page: 1,
      pageSize: 15,
      isMobile: false, //是否是 移动端
      modulesId: this.$route.query.id,
      isBack: true,
    };
  },
  async created() {
    this.url = window.location.href;
    await this.getLoginMsg();


    // 本地调试
    // await this.findModularStyleByOrgId(9542)
    // await this.loadData(
    //         9542,
    //         15635605813,
    //         1000058,
    //         "wxc8c8428d0d0fb353"
    // );

  },
  mounted() {
    this.showTime = false;
    this.startTime = formatDate(new Date(), "yyyy-MM-dd hh:mm:ss");
    document.addEventListener("click", (e) => {
      const node = document.getElementById("dataPanel");
      if (
        node &&
        this.selectPanelNode != null &&
        !node.contains(e.target) &&
        !this.selectPanelNode.contains(e.target)
      ) {
        this.showTime = false;
      }
    });

    window.onresize = () => {
      return (() => {
        const width =
          document.documentElement.clientWidth || document.body.clientWidth;
        if (width <= 800) {
          this.top = null;
          this.bottom = 0;
          this.isMobile = true;
        } else {
          this.bottom = null;
          this.top = 0;
          this.isMobile = false;
        }
      })();
    };
  },
  beforeDestroy() {
    // 正式打开
    this.buriedPointTotal(
      this.agentid,
      this.dateFormat(new Date().valueOf() - this.visitTime.valueOf()),
      this.appid
    );
  },
  directives: {
    //滑动指令
    touch: {
      bind: function (el, binding, vnode) {
        var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0;
        var direction = "";
        //滑动处理
        var startX, startY;

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI;
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY;
          var dx = endX - startX;
          var result = 0;

          //如果滑动距离太短
          if (Math.abs(dx) < 180 && Math.abs(dy) < 180) {
            return result;
          }

          var angle = GetSlideAngle(dx, dy);
          if (angle >= -45 && angle < 45) {
            result = "swiperight";
          } else if (angle >= 45 && angle < 135) {
            result = "swipeup";
          } else if (angle >= -135 && angle < -45) {
            result = "swipedown";
          } else if (
            (angle >= 135 && angle <= 180) ||
            (angle >= -180 && angle < -135)
          ) {
            result = "swipeleft";
          }
          return result;
        }

        el.addEventListener(
          "touchstart",
          function (ev) {
            startX = ev.touches[0].pageX;
            startY = ev.touches[0].pageY;

            //判断长按
            timeOutEvent = setTimeout(() => {
              timeOutEvent = 0;
              if (touchType === "press") {
                binding.value();
              }
            }, 500);
          },
          false
        );

        el.addEventListener("touchmove", function (ev) {
          clearTimeout(timeOutEvent);
          timeOutEvent = 0;
        });

        el.addEventListener(
          "touchend",
          function (ev) {
            var endX, endY;
            endX = ev.changedTouches[0].pageX;
            endY = ev.changedTouches[0].pageY;
            direction = GetSlideDirection(startX, startY, endX, endY);

            clearTimeout(timeOutEvent);

            switch (direction) {
              case 0:
                break;
              case "swipeup":
                if (touchType === "swipeup") {
                  binding.value();
                }
                break;
              case "swipedown":
                if (touchType === "swipedown") {
                  binding.value();
                }
                break;
              case "swipeleft":
                if (touchType === "swipeleft") {
                  binding.value();
                }
                break;
              case "swiperight":
                if (touchType === "swiperight") {
                  binding.value();
                }
                break;
              default:
            }
          },
          false
        );
      },
    },
  },
  methods: {
    async findModularStyleByOrgId(orgId){
      try {
        let result = await findWXModularStyleByOrgId(orgId)
        console.log('JSON.parse(result).',JSON.parse(result))
        this.modalType = String(JSON.parse(result).action)
        this.backdrop = String(JSON.parse(result).backdrop)
      }catch (e) {
        console.log(e)
      }

      // sessionStorage.setItem('action',JSON.parse(String(JSON.parse(result).action)))
      // sessionStorage.setItem('backdrop',JSON.parse(String(JSON.parse(result).backdrop)))
    },
    rightSlide() {
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (JSON.parse(sessionStorage.getItem("from")) == "/") {
        window.wx.closeWindow();
      }
    },
    async getSignature(agentid, appid) {
      let url = window.location.href.split("#")[0];
      try {
        let data = {
          url: url,
          agentId: agentid,
        };
        console.log("data");
        console.log(data);
        let res = await getSignature(data);
        console.log(res);
        let result = JSON.parse(res);
        console.log(result);
        wx.agentConfig({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          corpid: appid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
          nonceStr: String(result.nonce_str), // 必填，生成签名的随机串
          signature: String(result.signature), // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: [
            "onHistoryBack",
            "closeWindow",
            "onMenuShareAppMessage",
          ], //必填，传入需要使用的接口名称
          success: function (res) {
            wx.onHistoryBack(function () {
              if (JSON.parse(sessionStorage.getItem("from")) == "/") {
                wx.closeWindow();
              } else {
                let agentid = JSON.parse(sessionStorage.getItem("agentid"));
                let appid = JSON.parse(sessionStorage.getItem("appid"));
                // sessionStorage.clear();
                this.$router.push({
                  path: "/LoginCodes",
                  query: {
                    agentid: agentid,
                    appid: appid,
                  },
                });
              }
              return true;
            });
            wx.onMenuShareAppMessage({
              title: "舆情报告", // 分享标题
              desc: "随时查阅舆情报告，及时掌握关键信息。 ", // 分享描述
              link: window.location.href, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
              imgUrl:
                "http://inews5.xlmediawatch.com/reportFile/asslogjs/1047/lnewsImg.png", // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
          },
          fail: function (res) {
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    //时间戳转天数
    dateFormat(times) {
      let s =
        Math.floor((times / 1000) % 60) < 10
          ? "0" + Math.floor((times / 1000) % 60)
          : Math.floor((times / 1000) % 60);
      let m =
        Math.floor((times / 1000 / 60) % 60) < 10
          ? "0" + Math.floor((times / 1000 / 60) % 60)
          : Math.floor((times / 1000 / 60) % 60);
      let h =
        Math.floor(times / 1000 / 60 / 60) < 10
          ? "0" + Math.floor((times / 1000 / 60 / 60) % 24)
          : Math.floor((times / 1000 / 60 / 60) % 24);
      return h + "小时" + m + "分" + s + "秒";
    },
    //埋点
    async buriedPointTotal(agentid, visitDuration, appid) {
      let data = {
        corpId: appid, //企业id
        agentId: agentid, //应用id
        userId: this.userInfo.userId, //用户id
        userName: this.userInfo.userInfo.name, //用户名称
        moduleName: decodeURIComponent(this.moduleName), //模块名称
        moduleId: this.$route.query.moduleId, //模块id
        mobile: this.userInfo.userInfo.mobile, //手机号
        reportName: decodeURIComponent(this.reportName), //报告名称
        departmentName: this.userInfo.department,
        reportId: this.$route.query.reportId, //报告id
        visitType: "", //访问页面类型
        visitUrl: this.url, //访问页面
        visitDuration: visitDuration, //访问时长
        operationName: "", //操作名称
        visitTime: this.visitTime, //访问时间
      };
      try {
        await sendBuriedPointTotal(data);
      } catch (e) {
        console.log(e);
      }
    },
    paginationChange(type, val) {
      if (type == "page") {
        this.page = val;
      }
      if (type == "pageSize") {
        this.pageSize = val;
      }
      this.loadData();
    },
    scrollHandler() {
      if (this.isMobile && this.loading === false) {
        this.pageSize = 15;
        let pageNum = this.total / this.pageSize;
        if (this.total % this.pageSize != 0) {
          pageNum++;
        }
        if (this.page < pageNum) {
          this.loading = true;
          this.page++;
          this.loadData();
        }
      }
    },
    loadData(orgId, userId, agentid, appid) {
      // 正式打开
      this.getSignature(agentid, appid);
      let body = {
        userId: userId,
        orgId: orgId,
        page: this.page,
        pageSize: this.pageSize,
        modulaId: parseInt(this.$route.query.moduleId),
      };
      getReportTimerReport(body)
        .then((res) => {
          console.log(JSON.parse(res));
          const data = JSON.parse(res);
          this.total = data.total;
          this.dataList = data.list;
          console.log('this.dataList',this.dataList)
        })
        .then((res) => {
          console.log(this.dataList);
          this.agentid = agentid;
          this.appid = appid;
          this.visitTime = new Date();
          // 正式打开
          this.buriedPointTotal(agentid, -1, appid);

          this.loading = false;
          this.show = true;
        });
    },
    timeChanged(val, type, flag) {
      if (flag) {
        this.startTime = formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
        if (type != "month" && type != "year") {
          // this.showTime = false
          this.changeShowReportList(this.selectItem);
        }
      }
    },
    async changeShowReportList(val) {
      await this.getEffectiveTime(val.id);
      // if(this.showTime){
      //   return
      // }
        if (!this.newDay) {
          this.backId = val.id;
          this.selectItem = val;
          this.$confirm("未查询到相关报告，请选择其他报告列表查看", "提示", {
            confirmButtonText: "确定",
            type: "warning",
            center: true,
          })
                  .then(() => {
                    this.loading = false;
                  })
                  .catch(() => {
                    this.loading = false;
                  });
        } else {
          this.$router.push({
            path: "reportInfo",
            query: {
              needBack: true,
              startTime: this.startTime,
              reportName: val.reportListName,
              cycleType: val.cycleType,
              reportId: val.id,
              moduleName: this.moduleName,
              agentid: this.agentid,
              appid: this.appid,
              moduleId: this.$route.query.moduleId,
            },
          });
        }


    },
    async showReportList(val) {
      if(val.clickState == 1){
        this.$router.push({
          path: "/reportListsDetail",
          query: {
            moduleId: this.$route.query.moduleId,
            name: this.$route.query.name,
            rcode: this.code,
            moduleName: this.moduleName,
            agentid: this.agentid,
            appid: this.appid,
            needNotLogin: true,
            reportId: val.id,
          },
        });
      }else{
        await this.getEffectiveTime(val.id);
        if (this.showTime) {
          return;
        }

        if (!this.newDay) {
          this.backId = val.id;
          this.selectItem = val;
          this.$confirm("未查询到相关报告，请选择其他报告列表查看", "提示", {
            confirmButtonText: "确定",
            type: "warning",
            center: true,
          })
                  .then(() => {
                    this.loading = false;
                  })
                  .catch(() => {
                    this.loading = false;
                  });
        } else {
          this.$router.push({
            path: "reportInfo",
            query: {
              needBack: true,
              cycleType: val.cycleType,
              startTime: this.newDay,
              reportName: val.reportListName,
              reportId: val.id,
              moduleName: this.moduleName,
              agentid: this.agentid,
              appid: this.appid,
              moduleId: this.$route.query.moduleId,
            },
          });
        }
      }

      // }

    },
    async getEffectiveTime(id) {
      try {
        let data = {
          id: id,
        };
        let result = await getEffectiveTime(data);
        let arr = [];
        this.newDay = JSON.parse(result)[0];
        JSON.parse(result).map((m) => {
          arr.push(m.split(" ")[0]);
        });
        this.effectiveTime = arr;
      } catch (e) {
        console.log(e);
      }
    },
    showTimeSelect(id, val) {
      if(val.clickState == 0){
        this.getEffectiveTime(val.id);
        this.timeType = Number(val.cycleType) - 1;
        this.backId = id;
        this.selectItem = val;
        this.showTime = true;
        const height =
                document.documentElement.clientHeight || document.body.clientHeight;
        this.selectPanelNode = document.getElementById(id);
        let conpomentWidth;
        if (this.isMobile) {
          //移动端
          this.top = null;
          this.bottom = 0;
        } else {
          conpomentWidth = 330;
          //web端
          //点击节点
          if (this.selectPanelNode) {
            let relativeTopHeight = null;
            const nodeHeight = this.selectPanelNode.offsetTop;
            if (nodeHeight > height) {
              relativeTopHeight = nodeHeight % height;
            } else {
              relativeTopHeight = nodeHeight;
            }
            if (relativeTopHeight + conpomentWidth > height) {
              relativeTopHeight = relativeTopHeight - conpomentWidth;
            }
            this.top = Math.abs(relativeTopHeight);
            this.bottom = null;
          }
        }
      }else{
        this.showReportList(val)
      }

    },
    async getLoginMsg() {
      let agentid = JSON.parse(sessionStorage.getItem("agentid"));
      this.agentid = agentid;
      let appid = JSON.parse(sessionStorage.getItem("appid"));
      this.appid = appid;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.userInfo = userInfo;
      let modularsArr = userInfo.modulars.split(",");
      await this.findModularStyleByOrgId(this.userInfo.orgId)
      if (modularsArr.includes(this.$route.query.moduleId)) {
        await this.loadData(
          this.userInfo.orgId,
          this.userInfo.userId,
          agentid,
          appid
        );
      } else {
        this.$router.push({ path: "/noPermissions" });
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../assets/css/less/style";
.detailedBtn {
  font-weight: 700;
  font-size: 30px;
  background-color: #0086f9;
  color: white;
  /*float: right;*/
  margin-left: 5px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 1px 1px 1px #0086f9;
}
.JH-body {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1vw;

  .header_JH {
    width: 100%;
    /*height: 100%;*/
    font-weight: bold;
    margin-bottom: 1rem;
    color: #43556c;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .content_JH {
    width: 100%;
    height: calc(100vh - 2vw - 70px);
    overflow: hidden;
    overflow-y: scroll;
    @media screen and (max-width: 800px) {
      > :first-child {
        margin-top: 1rem;
      }
      height: calc(100vh - 2vw - 1rem);
    }
    p {
      background-color: white;
      overflow: hidden;
      padding: 1rem 0 1rem 2rem;
      border: 1px solid #dddee3;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: left;
      margin-top: 0.5rem;
      @media screen and (min-width: 800px) {
        margin-top: 0;
        padding: 2rem 1rem;
        border-radius: 0;
        border-top: none;
        border-left: none;
        border-right: none;
      }
      > :nth-child(1) {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-bottom: 1px solid;
        border-radius: 50%;
        margin-right: 1rem;
        @media screen and (min-width: 800px) {
          //display: none;
          height: 2rem;
          border-radius: 50px;
        }
      }

      > :nth-child(2) {
        display: inline-block;
        word-wrap: break-word;
        word-break: normal;
        font-weight: bold;
        font-size: 20px;
        @media screen and (max-width: 300px) {
          max-width: 250px;
        }
        @media screen and (min-width: 300px) and (max-width: 400px) {
          max-width: 230px;
        }
        @media screen and (min-width: 400px) and (max-width: 500px) {
          max-width: 290px;
        }
        @media screen and (min-width: 500px) and (max-width: 800px) {
          min-width: 300px;
          max-width: 600px;
        }
        @media screen and (min-width: 800px) {
          font-size: 1rem;
          font-weight: normal;
        }
      }

      > :nth-child(3) {
        margin-left: auto;
        margin-right: 1rem;
        padding: 0.5rem 1rem;
        border-radius: 50px;
        color: white;
        @media screen and (min-width: 800px) {
          margin-right: 3rem;
          color: white;
          .color_blue();
          background-color: white !important;
        }
        span {
          border: solid;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          @media screen and (max-width: 800px) {
            display: none;
          }
        }
      }
    }
    > span {
      width: 100%;
      padding: 1rem;
      display: inline-block;
      text-align: center;
    }
  }
}

.timeSelectPanel_JH {
  background-color: white;
}
.paginationClass {
  position: fixed;
  padding: 20px;
  bottom: 0px;
  left: 0;
  right: 0px;
  display: flex;
  > div:nth-of-type(1) {
    margin-left: auto;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
}
</style>
<style>
.el-message-box {
  width: 90vw;
  max-width: 600px;
}
.el-message-box__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped>
  #reportListIndex {
    box-sizing: border-box;
    overflow: scroll;
    padding-top: 19px;
    height: 100vh;
    width: 100vw;
  }
  .backImg{
    background-image: url(./imgReports/底色.png);
    background-size: 100% 100%;
  }

  .listContent {
    margin-left: 11px;
    width: calc(100vw - 22px);
    padding: 0 24px;
    font-size: 20px;
    font-family: '黑体';
    font-weight: 600;
    box-sizing: border-box;
    line-height: 69px;
    border-radius: 4px;
    height: 69px;
    background-color: #fff;
  }
  .listContent>span{
    cursor: pointer;
  }
  .toWatch {

    box-sizing: border-box;
    width: calc(100vw - 22px);
    margin: 8px 11px 10px 11px;
    text-align: right;
  }

  .toWatch>p {
    font-size: 15px;
    color: #4786FA;
    cursor: pointer;
  }
</style>
